@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* CSS HEX */
:root {
  --dark-jungle-green: #20221bff;
  --rifle-green: #424c42ff;
  --wintergreen-dream: #5f8f88ff;
  --timberwolf: #d3d0cbff;
  --cultured: #f1f1f1ff;
  --maximum-yellow-red: #efbb43ff;
  --melon: #f3c4b6ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

section {
  padding: 1rem 1rem 1rem 1rem;
  color: var(--dark-jungle-green);
}

.section-container {  
  border-bottom-style: solid;
  border-color: var(--timberwolf);
  border-width: 2px;
}

#expand-all-button {
  background-color: var(--timberwolf);
  text-align: right;
  padding: 2px 1rem 2px 1rem;
  cursor: pointer;
}

#expand-all-button:hover {
  padding: 2px 2rem 2px 1rem;
  transition-duration: 500ms;
  border-bottom-style: solid;
  border-width: 5px;
  border-color: var(--maximum-yellow-red);
}

.other-content-row {
  padding: 0 0 10px 0;;
}

.section-footer {
  background-color: var(--dark-jungle-green);
  color: var(--cultured);
  padding: 5px;
  align-items: center;
}

.section-footer a {
  margin: 5px;
  color: var(--cultured);
}

.section-footer a:hover {
  color: var(--maximum-yellow-red);
}

.section-footer span {
  margin: 5px;
}