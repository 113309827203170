@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS HEX */
:root {
  --dark-jungle-green: #20221bff;
  --rifle-green: #424c42ff;
  --wintergreen-dream: #5f8f88ff;
  --timberwolf: #d3d0cbff;
  --cultured: #f1f1f1ff;
  --maximum-yellow-red: #efbb43ff;
  --melon: #f3c4b6ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

section {
  padding: 1rem 1rem 1rem 1rem;
  color: #20221bff;
  color: var(--dark-jungle-green);
}

.section-container {  
  border-bottom-style: solid;
  border-color: #d3d0cbff;
  border-color: var(--timberwolf);
  border-width: 2px;
}

#expand-all-button {
  background-color: #d3d0cbff;
  background-color: var(--timberwolf);
  text-align: right;
  padding: 2px 1rem 2px 1rem;
  cursor: pointer;
}

#expand-all-button:hover {
  padding: 2px 2rem 2px 1rem;
  transition-duration: 500ms;
  border-bottom-style: solid;
  border-width: 5px;
  border-color: #efbb43ff;
  border-color: var(--maximum-yellow-red);
}

.other-content-row {
  padding: 0 0 10px 0;;
}

.section-footer {
  background-color: #20221bff;
  background-color: var(--dark-jungle-green);
  color: #f1f1f1ff;
  color: var(--cultured);
  padding: 5px;
  align-items: center;
}

.section-footer a {
  margin: 5px;
  color: #f1f1f1ff;
  color: var(--cultured);
}

.section-footer a:hover {
  color: #efbb43ff;
  color: var(--maximum-yellow-red);
}

.section-footer span {
  margin: 5px;
}
:root {
  --dark-jungle-green: #20221bff;
  --rifle-green: #424c42ff;
  --wintergreen-dream: #5f8f88ff;
  --timberwolf: #d3d0cbff;
  --cultured: #f1f1f1ff;
  --maximum-yellow-red: #efbb43ff;
  --melon: #f3c4b6ff;
}

/* overall navbar */
#navbar {
    background-color: #20221bff;
    background-color: var(--dark-jungle-green) ;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    color: #f1f1f1ff;
    color: var(--cultured);
    position: fixed;
    /* top: 0px; */
    z-index: 1000;
  }

#sidebar-button {
  padding: 2px;
}

#sidebar-button:hover {
  border-radius: 4px;
  background-color: #424c42ff;
  background-color: var(--rifle-green);
  transition-duration: 0.3s;
  cursor: pointer;
}

#navbar-name {
  font-size: 1.3rem;
  margin-left: 16px;
}

#navbar-name:hover { 
  background-color: #efbb43ff; 
  background-color: var(--maximum-yellow-red);
  color: #20221bff;
  color: var(--dark-jungle-green);
  transition-duration: 0.5s;
  cursor: pointer;
}

/* sidebar */
.nav-sidebar-menu {
  background-color: #20221bff;
  background-color: var(--dark-jungle-green);
  color: #f1f1f1ff;
  color: var(--cultured);
  width: 275px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  /* left: 0; */
  transition: 850ms;
  z-index: 1000;
}

.nav-sidebar-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-sidebar-menu-items {
  width: 100%;
  padding-top: 20px;
}

.nav-sidebar-toggle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: left;
  padding-left: 16px;
}

#nav-sidebar-cross-button:hover {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  cursor: pointer;
}

.nav-sidebar-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  border-radius: 4px;
}

.nav-sidebar-text a {
  text-decoration: none;
  color: #f1f1f1ff;
  color: var(--cultured);
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-sidebar-text a:hover {
  background-color: #424c42ff;
  background-color: var(--rifle-green);
  color: #f1f1f1ff;
  color: var(--cultured);
  padding: 0 16px;
  transition-duration: 500ms;
  cursor: pointer;
}

.nav-sidebar-text-span {
  margin-left: 16px;
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
}

.landing-page {
    height: 100vh;
    background-image: url(/static/media/street-5130030_1920.fdd63f97.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-size: 60px;
    z-index: 0;
}

.backdrop {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: black;
    z-index: 1;
}

.picture-title {
    position: relative;
    z-index: 100;
}

.bottom-left {
    position: absolute;
    color: #f1f1f1ff;
    color: var(--cultured);
    bottom: 15px;
    left: 20px;
    font-size: 1rem;
}

.front-row {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh;
    color: #f1f1f1ff;
    color: var(--cultured);
    text-align: center;
    padding: auto;
    z-index: 10;
}

.greetings {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
}

.greetings > p{
    font-size: 20px;
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }

#about-me-container {
    align-items: center;
}
  
#about-me-pictures {
    width: 100%;
    padding: 10px;
}

#about-me-text {
    background-color: #d3d0cbff;
    background-color: var(--timberwolf);
    padding: 2rem;
}

#about-me-text h2 {
    background-color: #efbb43ff;
    background-color: var(--maximum-yellow-red);
    padding-left: 0.5rem;
}

#about-me-text p {
    padding-left: 0.5rem;
}

:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }

.artwork-panel {
    background-color: #20221bff;
    background-color: var(--dark-jungle-green);
}

.section-artworks {
    z-index: 100;
    padding: 40px 50px 20px 50px;
    align-items: center;
    
}

.section-artworks-work {
    justify-content: center;
    margin: 15px;
}

.section-artworks img { 
    max-width: 100%;
}

.section-artworks span { 
    color: #f1f1f1ff; 
    color: var(--cultured);
}

.section-artworks h4 { 
    color: #f1f1f1ff; 
    color: var(--cultured);
}

#title {
    color: #efbb43ff;
    color: var(--maximum-yellow-red);
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }
  
.section-workexp-header {
    align-items: center;
}

/* overall panel */
.workexp-panel {
    background-color: #d3d0cbff;
    background-color: var(--timberwolf);
    margin: 0 0 3px 0;
    width: 100%;
}

.workexp-panel:hover {
    background-color: #efbb43ff;
    background-color: var(--maximum-yellow-red);
    cursor: pointer;
    transition-duration: 400ms;
}


/* panel button */
.workexp-panel-btn {
    padding: 1rem;
    width: 100%;
}

.workexp-pic {
    align-items: center;
    width: 100%;
}

.workexp-pic img {
    max-width: 30vw;
    padding: 15px;
}

.workexp-panel-btn h4 {
    background-color: #20221bff;
    background-color: var(--dark-jungle-green);
    padding: 0.5rem;
    color: #f1f1f1ff;
    color: var(--cultured);
}

.workexp-panel-btn h4:hover {
    color: #efbb43ff;
    color: var(--maximum-yellow-red);
    transition-duration: 300ms;
}

.workexp-panel-btn img { 
    width: 100%;
}

.workexp-panel-row span{
    color: #f1f1f1ff;
    color: var(--cultured);
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }

.section-projects {
    width: 100%;
    margin: 0;
    padding: 0;
}

.section-container {
    padding: 0;
}

/* header of projects */
.section-projects-header {
    align-items: center;
}

/* collapsible panels header */
.projects-panel {
    background-color: #d3d0cbff;
    background-color: var(--timberwolf);
    width: 100%;
}

.projects-panel-btn {
    background-color: #20221bff;
    background-color: var(--dark-jungle-green);
    color: #f1f1f1ff;
    color: var(--cultured);
    cursor: pointer;
    padding: 1rem;
    border-bottom-style: solid;
    border-width: 3px;
    border-color: #efbb43ff;
    border-color: var(--maximum-yellow-red);
}

.projects-panel-btn:hover {
    background-color: #424c42ff;
    background-color: var(--rifle-green);
    color: #efbb43ff;
    color: var(--maximum-yellow-red);
    transition-duration: 500ms;
    transition: 500ms;
}

/* collapsible panels content */
.projects-panel-content {
    width: 100%;
    padding: 1rem;
}

.projects-panel-row {
    align-items: center;
}

.projects-panel-content img { 
    width: 100%;
}

.projects-link {
    width: 100%;
    text-align: center;
    color: #f1f1f1ff;
    color: var(--cultured);
    background-color: #20221bff;
    background-color: var(--dark-jungle-green);
    padding: 10px;
}

.projects-link a {
    color: #f1f1f1ff;
    color: var(--cultured);
}

.projects-link a:hover {
    color: #20221bff;
    color: var(--dark-jungle-green);
    background-color: #efbb43ff;
    background-color: var(--maximum-yellow-red);
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }

#section-achievements {
    background-image: url(/static/media/background-achievements.27f74f2d.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#section-achievements h1{
    width: 100%;
    background-color: #f1f1f1ff;
    background-color: var(--cultured);
}

.achievements-card {
    background-color: #d3d0cbff;
    background-color: var(--timberwolf);
    padding: 1rem;
    margin: 1rem 0 1rem 0;
}

.achievements-card-content {
    align-items: flex-start;
}

.achievements-card-content img {
    width: 100%;
    max-width: 40vw;
}

.achievements-card-content h4 {
    background: #20221bff;
    background: var(--dark-jungle-green);
    color: #f1f1f1ff;
    color: var(--cultured);
    padding: 3px 3px 3px 8px;
}

.achievements-card-content h5 {
    background: #5f8f88ff;
    background: var(--wintergreen-dream);
    color: #f1f1f1ff;
    color: var(--cultured);
    padding: 3px 3px 3px 8px;
}
:root {
    --dark-jungle-green: #20221bff;
    --rifle-green: #424c42ff;
    --wintergreen-dream: #5f8f88ff;
    --timberwolf: #d3d0cbff;
    --cultured: #f1f1f1ff;
    --maximum-yellow-red: #efbb43ff;
    --melon: #f3c4b6ff;
  }

.section-artworks {
    background-color: #20221bff;
    background-color: var(--dark-jungle-green);
    padding: 5px;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: #efbb43ff;
    border-color: var(--maximum-yellow-red);
}

.artworks-container {
    color: #f1f1f1ff;
    color: var(--cultured);
    text-align: center;
    padding: 0;
}

.moreworks-button {
    margin: 5px;
    align-items: center;
    width: 100%;
}

.moreworks-button:hover {
    color: #efbb43ff;
    color: var(--maximum-yellow-red);
    cursor: pointer;
}

.artworks-image {
    margin: 10px 0 10px 0;
}
